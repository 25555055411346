<ion-header>
  <ion-toolbar color="green">

    <ion-title *ngIf="isForced === 'yes'">Update vereist</ion-title>

    <ng-container *ngIf="isForced === 'no'">
      <ion-title>Update binnenkort</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="closeModal()">
          <ion-icon name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ng-container>

  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <ion-grid>
    <ion-row>
      <ion-col>
        <p *ngIf="isForced === 'yes'">
          Er is een update beschikbaar voor de app. De huidige versie wordt niet meer ondersteund. <br /><br />We raden aan om de laatste versie te downloaden om de app te kunnen blijven gebruiken.
        </p>
        <p *ngIf="isForced === 'no'">
          Er is een update beschikbaar voor de app. De huidige versie wordt nog wel ondersteund, maar we raden aan om de laatste versie te downloaden om de app te kunnen blijven gebruiken.
        </p>
        <ion-button color="salmon" (click)="openStorePage()">Update downloaden</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
