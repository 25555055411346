import { appVersion } from './../env/app-version';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/capacitor';
import * as SentryAngular from '@sentry/angular';
import { Platform } from '@ionic/angular';
import { ENV } from './../env/env';

@Injectable()
export class AppSettings {

  constructor(
    private readonly platform: Platform,
  ) {

    Sentry.init({
      dsn: this.sentryDsn,
      environment: this.channel,
      release: appVersion,
      ignoreErrors: [
        'installations/missing-app-config-values',
        'Error: not implemented O@capacitor',
        'Cannot read properties of undefined (reading "icons"'
      ]
    },
    SentryAngular.init);

    this.platform.ready().then(() => {
      JSON.stringify(this.platform.platforms);
      Sentry.addBreadcrumb({
        category: 'platforms',
        message: JSON.stringify(this.platform.platforms()),
      });

      Sentry.addBreadcrumb({
        category: 'channel',
        message: this.channel,
      });
    });

    console.log('Running in ', this.channel);
  }

  production = ENV.production;

  get channel() {
    const url = new URL(window.location.href);

    if (navigator.userAgent === 'Cypress') {
      return this.channels.TEST;
    } else {
      switch (url.host) {
      case 'verkoop.boekenbalie.nl': {
        return this.channels.PRODUCTION_PWA;
      }
      case 'staging-boekenbalie.firebaseapp.com': {
        return this.channels.STAGING;
      }
      case 'develop-boekenbalie.firebaseapp.com': {
        return this.channels.DEV;
      }
      default: if (this.production && this.platform.is('cordova')) {
        return this.channels.PRODUCTION_MOBILE;
      } else if (this.platform.is('cordova')) {
        return this.channels.DEV_STAGING_MOBILE;
      } else if (['localhost:8100', '0.0.0.0:8100', '127.0.0.1:8100'].indexOf(url.host) > -1) {
        return this.channels.LOCAL;
      } else {
        return this.channels.UNKNOWN;
      }
      }
    }
  }

  channels = {
    STAGING: 'staging',
    DEV: 'dev',
    TEST: 'test',
    PRODUCTION_MOBILE: 'production_mobile',
    PRODUCTION_PWA: 'production_pwa',
    DEV_STAGING_MOBILE: 'dev_staging_mobile',
    LOCAL: 'local',
    UNKNOWN: 'unknown',
  };

  get baseUrl() {
    switch (this.channel) {
    case this.channels.PRODUCTION_PWA:
    case this.channels.PRODUCTION_MOBILE:
      return 'https://api.boekenbalie.nl/api/v2';
    default:
      return 'https://stg-boekenbalie-frontend-api.herokuapp.com/api/v2';
    }
  }

  get enableDevConfig() {
    return this.channel !== this.channels.PRODUCTION_PWA &&
      this.channel !== this.channels.PRODUCTION_MOBILE;
  }

  public sentryDsn = 'https://e272c2464c3a4cbb84008cbf0221cce6@sentry.io/1417281';

  // setChannel(channel) {
  //   window.localStorage.setItem('channel', channel);
  //   window.location.reload();
  //   this.storageService.clear();
  // }

  storageKeys = {
    user_id: 'bb_user_id',
    access_token: 'bb_access_token',
    expires_at: 'bb_expires_at',
    list: 'bb_list',
    user_auth: 'user_auth',
  };

  events = {
    UserLoggedInEvent: 'UserLoggedInEvent',
    SessionInvalidEvent: 'SessionInvalidEvent',
    UserLoggedOutEvent: 'UserLoggedOutEvent',
  };

  //   tags:
  // - name: users
  //   description: User management
  // - name: misc
  //   description: Miscelaneous API endpoints
  // - name: books
  //   description: Book management
  // - name: lists
  //   description: Booklist management
  // - name: logistics
  //   description: Logistical endpoints
  API = {
    Users: {
      New: `${this.baseUrl}/users/new`,
      Register: `${this.baseUrl}/users/%user_id%`,
      Login: `${this.baseUrl}/users/login`,
      Logout: `${this.baseUrl}/users/logout`,
      Profile: `${this.baseUrl}/users/%user_id%`,
      Revalidate: `${this.baseUrl}/users/%user_id%`,
      UpdateProfile: `${this.baseUrl}/users/%user_id%`,
      Delete: `${this.baseUrl}/users/%user_id%`,

      ResetRequest: `${this.baseUrl}/users/reset`,
      ResetPassword: `${this.baseUrl}/users/password`,
      UpdatePassword: `${this.baseUrl}/users/%user_id%/password`,
    },
    Service: {
      Check: `${this.baseUrl}/service?pickup_minimum_amount=%pickup_minimum_amount%%&sale_minimum_amount=%sale_minimum_amount%&promotion_bonus_amount=%promotion_bonus_amount%&can_upgrade=%can_upgrade%`,
      Terms: `${this.baseUrl}/terms`,
      Address: `${this.baseUrl}/address?postalcode=%postalcode%&number=%number%`,
      CheckPromotionalCode: `${this.baseUrl}/check?what=promo&code=%code%`,
    },
    Books: {
      Isbn: `${this.baseUrl}/books/%isbn%`,
      PriceByBookId: `${this.baseUrl}/books/%book_id%/price`,
    },
    Lists: {
      GetAll: `${this.baseUrl}/lists`,
      ListNew: `${this.baseUrl}/lists/new`,
      ListById: `${this.baseUrl}/lists/%list_id%`,
      ListDeleteById: `${this.baseUrl}/lists/%list_id%`,
      AddBookToList: `${this.baseUrl}/lists/%list_id%/book/%book_id%`,
      DeleteBookFromList: `${this.baseUrl}/lists/%list_id%/book/%book_id%`,
      SubmitBookList: `${this.baseUrl}/lists/%list_id%/submit`,
      RescheduleBookList: `${this.baseUrl}/lists/%list_id%/reschedule`,
      GetShippingLabelBookList: `${this.baseUrl}/lists/%list_id%/label`,
    },
    Logistics: {
      DropOffLocations: `${this.baseUrl}/logistics/dropoff/locations?countrycode=%countrycode%&postalcode=%postalcode%`,
      PickupTimeslots: `${this.baseUrl}/logistics/pickup/days?countrycode=%countrycode%&postalcode=%postalcode%`,
    },
  };
}
