import { UpdatePage } from './../../pages/update/update';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { VersionInformation } from '../../interfaces/version';
import { compare } from 'compare-versions';

@Injectable()
export class UpdateModalProvider {

  public modal: HTMLIonModalElement;

  appVersion: string;
  versionInformation: VersionInformation;

  constructor(
    private modalCtrl: ModalController,
    private http: HttpClient,
  ) { }

  initialize(appVersion) {
    this.appVersion = appVersion;
    this.getVersionInformation();
  }

  getVersionInformation() {
    return this.http.get<VersionInformation>(
      'https://storage.googleapis.com/download/storage/v1/b/static_files_212/o/version.json?alt=media',
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }).subscribe((versionInformation) => {
      this.checkVersioning(versionInformation);
    });
  }

  checkVersioning(versionInformation) {
    if (compare(versionInformation.latestSupported, this.appVersion, '>')) {
      this.openModal(versionInformation.forced);
    }
  }

  private async openModal(forced: string) {
    this.modal = await this.modalCtrl.create({
      component: UpdatePage,
      componentProps: {
        isForced: forced,
      },
      showBackdrop: true,
      backdropDismiss: true,
    });

    this.modal.present();
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

}
