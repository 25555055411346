import { AppConfig } from './../../models/constants/app-config';
import { ScannerResultPage } from './../scanner-result/scanner-result';
import { ScannerResultDonablePage } from './../scanner-result-donable/scanner-result-donable';
import { ScannerModalPage } from './../scanner-modal/scanner-modal';
import { AnalyticsEventCategories, AnalyticsEventActions, AnalyticsEventTypes } from './../../models/constants/analytics-event-types';
import { BarcodeScannerProvider } from './../shared/barcode-scanner/barcode-scanner';
import { ScannerServiceProvider } from './../shared/scanner.service';
import { AlertMessages } from './../../models/constants/alert-messages';
import { ErrorUtil } from './../../providers/utils/error-util';
import { ProfileData } from '../../interfaces/users';
import { PlatformService } from '../../providers/utils/platform.service';
import { ProfileProvider } from '../../providers/profile/profile.service';
import { BookListModel } from './../../models/book-list';
import { BookListStore } from '../../providers/book/book-list-store';
import { BookProvider } from './../../providers/book/book.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, IonInput } from '@ionic/angular';
import { Browser } from '@capacitor/browser';
import { AlertsProvider } from '../../providers/alerts/alerts';
import { ToastProvider } from '../../providers/toast/toast';
import { ToastMessages } from '../../models/constants/toast-messages';
import { LoadingProvider } from '../../providers/loading/loading';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { InputValidator } from './../../validators/input.validator';
import * as Sentry from '@sentry/capacitor';
import { NavService } from './../../providers/utils/nav.service';
import { StateConditionsModalProvider } from './../../providers/utils/state-conditions.service';
import { AuthenticationModalProvider } from '../../providers/utils/authentication-modal.service';
import { AnalyticsProvider } from './../../providers/analytics/analytics';
import { StorageService } from './../../providers/utils/storage.service';

@Component({
  selector: 'page-start',
  templateUrl: 'scanner-start.html',
  styleUrls: ['./scanner-start.scss'],
})
export class ScannerStartPage implements OnInit {

  @ViewChild('isbnInput', { static: false }) isbnInput: IonInput;

  public modal: HTMLIonModalElement;

  form: FormGroup;
  submitted = false;
  bookList: BookListModel;
  profile: ProfileData;
  scanActive = false;

  // eslint-disable-next-line max-params
  constructor(
    public navService: NavService,
    public bookProvider: BookProvider,
    public barcodeScanner: BarcodeScannerProvider,
    public alertsProvider: AlertsProvider,
    public toastProvider: ToastProvider,
    public scannerService: ScannerServiceProvider,
    private bookListStore: BookListStore,
    private modalCtrl: ModalController,
    private errorUtil: ErrorUtil,
    private loadingProvider: LoadingProvider,
    private storageService: StorageService,
    private stateConditionsModal: StateConditionsModalProvider,
    private authModal: AuthenticationModalProvider,
    private formBuilder: FormBuilder,
    private analytics: AnalyticsProvider,
    private profileProvider: ProfileProvider,
    private platformService: PlatformService,
  ) {
    this.bookListStore.get().subscribe((bookList: BookListModel) => {
      this.bookList = bookList;
    });

    this.form = this.formBuilder.group({
      isbn: ['', Validators.compose([Validators.required])],
      suffix: false,
    }, { validators: this.isbnValid });

    this.form.valueChanges.subscribe(() => {
      this.submitted = false;
    });

    this.form.controls['isbn'].valueChanges.subscribe((v: string) => {
      this.form.controls['isbn'].setValue(v.replace(/\D/g, ''), { emitEvent: false });
    });

    this.profileProvider.profileData$.subscribe((profile: ProfileData) => {
      if (profile) {
        this.profile = profile;
      }
    });
  }

  ngOnInit() {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    this.analytics.analyticsEventSubject.next({eventName: AnalyticsEventTypes.PageOpen, eventData: { event_category: AnalyticsEventCategories.scanner, event_action: AnalyticsEventActions.PageOpen }});
    this.refreshTestISBN();
  }

  isbnValid(group: AbstractControl): ValidationErrors {
    return InputValidator.validateIsbn(group.get('isbn'), group.get('suffix').value);
  }

  public async openWithInAppBrowser(url: string) {
    await Browser.open({url});
  }

  public goToCheckoutList() {
    this.navService.navigateForward('checkout-list');
  }

  openConditionsModal() {
    this.stateConditionsModal.openModal(true);
  }

  async searchISBN() {
    this.scannerService.barcodeMode = false;
    this.submitted = true;

    if (!this.form.controls['isbn'].value || !this.form.valid) {
      this.toastProvider.showToast(ToastMessages.invalidISBN.text, ToastMessages.invalidISBN.duration, ToastMessages.invalidISBN.position);
      return false;
    }

    await this.doBookSearch(true, this.form.controls.isbn.value + (this.form.controls.suffix.value ? 'X' : ''));
  }

  resetISBNState() {
    this.submitted = false;
    this.form.controls.isbn.markAsUntouched();
  }

  async scanBarcode() {
    this.resetISBNState(); // reset displayed input errors

    // eslint-disable-next-line @typescript-eslint/naming-convention
    this.analytics.analyticsEventSubject.next({eventName: AnalyticsEventTypes.ClickButton, eventData: { event_category: 'scanner_start', event_label: 'scan_barcode' }});
    try {

      const isApp = await this.platformService.IsApp();
      if (!isApp) {
        this.scannerService.barcodeMode = true;
        this.showScannerModal();
      } else {
        this.scannerService.barcodeMode = true;
        const res = await this.barcodeScanner.scanBarcode();
        if (res && res.content) {
          this.doBookSearch(true, res.content);
        }
      }
    } catch (e) {
      Sentry.captureMessage('Scanner scanBarcode failed');
      Sentry.captureException(e);
    }
  }

  dismissBarcodeScanner() {
    this.analytics.analyticsEventSubject.next({eventName: AnalyticsEventTypes.ClickButton, eventData: { event_category: 'scanner_close', event_label: 'close_modal' }});
    this.barcodeScanner.dismissBarcodeScanner();
  }

  // To do: refactor function
  // eslint-disable-next-line sonarjs/cognitive-complexity
  async doBookSearch(clearISBN = false, scanISBN: string) {

    // Start by setting the loader
    const loader = await this.loadingProvider.createAndPresent(true);

    this.scannerService.setISBN(scanISBN);

    // get book info and book price
    try {
      const book = await this.scannerService.refreshBookInfo();
      if (book) {
        await this.scannerService.refreshBookPrice();
      }

      await loader.dismiss();

      const isAddable = await this.scannerService.checkLastScannedBookIsAddable();
      const isDonable = (await this.scannerService.checkLastScannedBookIsDonable()) && this.scannerService.donable && AppConfig.scanner.enableDonateOption;

      if (isAddable) {
        await this.showResultModal('addable');
      } else if (isDonable) {
        await this.showResultModal('donable');
      } else {
        if (clearISBN) {
          this.form.controls.isbn.setValue('');
        }
        this.tryFocusIsbnInput();
        this.tryContinueFastScan();
      }

    } catch (e) {
      await loader.dismiss();
      const notFound = e instanceof Object && e?.error?.status?.[0] === 'isbn_not_found';
      // removeView error is caused by calling loader.dismiss multiple times, work around by ignoring the exception
      if (e !== null && e !== false && e !== 'removeView was not found' && !notFound) {
        Sentry.captureMessage('Scanner doBookSearch failed');
        Sentry.captureException(e);
        let errorMessage = this.errorUtil.buildErrorMessage(e);
        if (!errorMessage) {
          errorMessage = AlertMessages.SomethingWentWrong.message;
        }
        await this.alertsProvider.showAlertInform(AlertMessages.Default.title, errorMessage, AlertMessages.Default.button1);
      }
      if (clearISBN && notFound) {
        this.form.controls.isbn.setValue('');
      }
      this.tryFocusIsbnInput();
      this.tryContinueFastScan();
    }
    this.refreshTestISBN();
  }

  async showScannerModal() {
    const scannerModal = await this.modalCtrl.create({ component: ScannerModalPage });
    scannerModal.onDidDismiss().then(async (result) => {
      if (result.data?.result) {
        this.doBookSearch(true, result.data.result);
      } else {
        this.analytics.analyticsEventSubject.next({eventName: AnalyticsEventTypes.ClickButton, eventData: { event_category: 'scanner_close', event_label: 'close_modal' }});
      }
    });
    return scannerModal.present();
  }

  async showResultModal(resultType: string) {

    let scanResultModal;

    if (resultType === 'addable') {
      scanResultModal = await this.modalCtrl.create({ component: ScannerResultPage });
    } else if (resultType === 'donable') {
      scanResultModal = await this.modalCtrl.create({ component: ScannerResultDonablePage });
    }

    scanResultModal.onDidDismiss().then(async (addToList) => {

      const loader = await this.loadingProvider.createAndPresent(true);
      this.form.controls.isbn.setValue('');
      this.form.controls.isbn.markAsUntouched();
      this.refreshTestISBN();

      if (addToList.data) {
        await loader.dismiss();
        await this.scannerService.addLastScannedBookToBookList();
        await this.checkConditionsModal();
        if (!this.scannerService.fastScan) {
          this.goToCheckoutList();
        }
        await this.showAccountReminder();
      }
      await loader.dismiss();
      this.tryContinueFastScan();
    });
    return scanResultModal.present();
  }

  tryContinueFastScan() {
    if (this.scannerService.fastScan && this.scannerService.barcodeMode) {
      this.scanBarcode();
    }
  }

  async checkConditionsModal() {
    if (this.bookList.length === 1) {
      const checkoutModalShown = await this.storageService.get('checkoutModalShown');
      if (!checkoutModalShown) {
        this.stateConditionsModal.openModal(true, true);
      }
    }
  }

  async showAccountReminder() {
    if (this.bookList.length === 3 && this.profile.role  === 'guest') {
      const clickedYes = await this.alertsProvider.showAlertConfirm(AlertMessages.createAccountScanner.title, AlertMessages.createAccountScanner.message, AlertMessages.createAccountScanner.button1, AlertMessages.createAccountScanner.button2);
      if (clickedYes) {
        this.authModal.openModal();
      }
    }
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

  async tryFocusIsbnInput() {
    await this.isbnInput.setFocus();
  }

  private async refreshTestISBN() {
    if (AppConfig.scanner.prefillISBN) {
      this.submitted = false;
      this.form.controls.isbn.setValue(await this.scannerService.getRandomTestIBSN());
    }
  }

}
