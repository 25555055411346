<ion-app>
  <div #smartbannercontainer></div>
  <ion-split-pane contentId="main" when="(min-width: 769px)">

    <ion-menu contentId="main" (ionDidClose)="menuClosed()">
      <ion-header class="hidden-md">
        <ion-toolbar color="medium">
          <ion-title>
            Menu
          </ion-title>
        </ion-toolbar>
        <ion-toolbar class="subheader-splitpane" color="green">
        </ion-toolbar>
      </ion-header>

      <ion-content color="light">
        <ion-list>
          <ion-list-header class="safe-area-menu-item-start">
            <ion-label>Start</ion-label>
          </ion-list-header>
          <div>
            <ion-menu-toggle autoHide="false">
              <ion-item *ngFor="let p of Pages" (click)="openPage(p)">
                <ion-label>{{p.title}}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-item>
              <ion-label>Snel scannen</ion-label>
              <ion-toggle slot="end" color="salmon" [(ngModel)]="scannerService.fastScan" (ngModelChange)="onToggle($event, 'fast_scan')"></ion-toggle>
            </ion-item>
            <ion-item *ngIf="appConfig.scanner.enableDonateOption">
              <ion-label>Donatie optie tonen</ion-label>
              <ion-toggle slot="end" color="salmon" [(ngModel)]="scannerService.donable" (ngModelChange)="onToggle($event, 'donable')"></ion-toggle>
            </ion-item>
          </div>

          <ion-list-header>
            <ion-label>Account</ion-label>
          </ion-list-header>

          <div *ngIf="!profile">
            <div>
              <ion-menu-toggle autoHide="false">
                <ion-item *ngFor="let p of loggedOutPages" (click)="openPage(p)">
                  <ion-label>{{p.title}}</ion-label>
                </ion-item>
              </ion-menu-toggle>
            </div>
          </div>

          <div *ngIf="profile">
            <div *ngIf="profile.role === 'guest' ">
              <ion-menu-toggle autoHide="false">
                <ion-item *ngFor="let p of loggedOutPages" (click)="openPage(p)">
                  <ion-label>{{p.title}}</ion-label>
                </ion-item>
              </ion-menu-toggle>
            </div>
          </div>

          <div *ngIf="profile">
            <div *ngIf="profile.role === 'unvalidated' || profile.role === 'registered'">
              <ion-menu-toggle autoHide="false">
                <ion-item *ngFor="let p of loggedInPages" (click)="openPage(p)">
                  <ion-label>{{p.title}}</ion-label>
                </ion-item>
                <ion-item (click)="doLogout()">
                  <ion-label>Uitloggen</ion-label>
                </ion-item>
              </ion-menu-toggle>
            </div>
          </div>

          <ion-list-header>
            <ion-label>Handige links</ion-label>
          </ion-list-header>
          <ion-menu-toggle autoHide="false">
            <ion-item (click)="openStateConditionsModal()">
              <ion-label>Conditievoorwaarden</ion-label>
            </ion-item>
            <ion-item (click)="openWithInAppBrowser('https://www.boekenbalie.nl/verpakkingsinstructies/')">
              <ion-label>Verpakkingsinstructies</ion-label>
            </ion-item>
            <ion-item (click)="openWithInAppBrowser('https://www.boekenbalie.nl/veelgestelde-vragen-verkopen-tweedehands-boeken')">
              <ion-label>Veelgestelde vragen</ion-label>
            </ion-item>
            <ion-item (click)="openWithInAppBrowser('https://www.boekenbalie.nl/over-boekenbalie/inkoopvoorwaarden/')">
              <ion-label>Inkoopvoorwaarden</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-list-header>
            <ion-label>Contactgegevens</ion-label>
          </ion-list-header>
          <ion-menu-toggle autoHide="false">
            <ion-item (click)="mailTo()"><ion-label>inkoop&#64;boekenbalie.nl</ion-label></ion-item>
            <ion-item (click)="openWithInAppBrowser('https://boekenbalie.nl/contact')"><ion-label>Contact</ion-label></ion-item>
          </ion-menu-toggle>
          <div class="version-label">Versie {{version}}
            <ion-menu-toggle autoHide="false" *ngIf="appSettings.enableDevConfig">
              <ion-icon [name]="devConfigPage.icon" (click)="openPage(devConfigPage)"></ion-icon>
            </ion-menu-toggle>
          </div>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main" swipeBackEnabled="false"></ion-router-outlet>
  </ion-split-pane>

</ion-app>
