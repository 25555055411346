import { SubmitError } from '../../models/submit-error';
import { BehaviorSubject } from 'rxjs';
import { LoggedOutEvent } from '../../models/constants/events';
import { AlertMessages } from '../../models/constants/alert-messages';
import { AlertsProvider } from '../alerts/alerts';
import { RefreshProfile } from '../../models/constants/events';
import { BoekenbalieApi } from '../api/boekenbalieApi';
import { LoginData, ProfileData, CheckoutPackData } from '../../interfaces/users';
import { AuthenticationProvider } from '../authentication/authentication';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { AuthenticationModalProvider } from '../utils/authentication-modal.service';
import { UserAuthModel } from '../../models/user-auth';
import { AppConfig } from '../../models/constants/app-config';
import { EventsService } from '../utils/events-service';
import {NavService} from '../utils/nav.service';

@Injectable()
export class ProfileProvider {

  // maybe move to different provider
  profileData: ProfileData = null;
  public profileData$ = new BehaviorSubject<ProfileData>(this.profileData);

  // must be saved locally because the modified postalcode and country can
  // only be saved in the API when the whole address is complete and valid
  public checkoutPackData: CheckoutPackData;

  constructor(
    public authenticationProvider: AuthenticationProvider,
    public boekenbalieApi: BoekenbalieApi,
    public storage: Storage,
    private events: EventsService,
    private authModal: AuthenticationModalProvider,
    private alertsProvider: AlertsProvider,
    public router: NavService,
  ) {

    this.authenticationProvider.userAuth$.subscribe((userAuthModel: UserAuthModel) => {
      if (userAuthModel && userAuthModel.auth_token) {
        this.refreshProfileData();
      }
    });

    this.events.refreshData.subscribe((eventName) => {
      if (eventName === LoggedOutEvent) {
        this.profileData = null;
        this.profileData$.next(this.profileData);
      }
    });

    this.events.refreshData.subscribe((eventName) => {
      if (eventName === RefreshProfile) {
        this.refreshProfileData();
      }
    });
  }

  async refreshProfileData() {
    const user = await this.authenticationProvider.getUserAuth();
    if (user && user.user_id) {
      const profileResp = await this.boekenbalieApi.profileData(user.user_id);
      if (profileResp && profileResp.userdata) {
        const profileData = profileResp.userdata;
        this.profileData = profileData;
        this.profileData$.next(profileData);
      }
    }
  }

  async resendConfirmationEmail() {
    return this.boekenbalieApi.profileRevalidateEmail(this.profileData$.value);
  }

  async setProfileData(data: ProfileData, isPartial = false) {
    this.profileData = isPartial ? { ...this.profileData, ...data } : data;
    this.profileData$.next(this.profileData);
  }

  async updateProfileData(profile: ProfileData, setCachedProfile = false, isPartial = false) {
    const user = await this.authenticationProvider.getUserAuth();
    const resp = await this.boekenbalieApi.profileUpdate(user.user_id, profile);
    if (setCachedProfile) {
      this.setProfileData(profile, isPartial);
    }
    return resp;
  }

  setCheckoutPackData(data: CheckoutPackData) {
    this.checkoutPackData = data;

    // postal code to default back end format for consistency, by removing spaces and making uppercase
    this.checkoutPackData.postalcode = this.checkoutPackData.postalcode.replace(' ', '').toUpperCase();
  }

  isCheckoutPackDataDirty(): boolean {
    if (!this.profileData || !this.checkoutPackData) {
      return false;
    }

    // check postal codes in same format by removing spaces and making uppercase on both sides
    return this.profileData.country !== this.checkoutPackData.country ||
      this.profileData.postalcode.replace(' ', '').toUpperCase() !==
      this.checkoutPackData.postalcode.replace(' ', '').toUpperCase();
  }

  async logout() {
    if (this.profileData$.value && this.profileData$.value.role === 'guest') {
      const resp = await this.alertsProvider.showAlertConfirm(AlertMessages.resetSession.title, AlertMessages.resetSession.message, AlertMessages.resetSession.button1, AlertMessages.resetSession.button2);
      if (resp === false) {
        return false;
      }
    }
    await this.handleLogout();
  }

  private async handleLogout() {
    await this.storage.remove('checkoutModalShown');
    this.profileData = null;
    this.authenticationProvider.deleteUserAuth(true);
  }

  async deleteUser() {
    await this.authenticationProvider.deleteUser();
    await this.handleLogout();
  }

  async login(loginData: LoginData): Promise<any> {
    return this.authenticationProvider.loginWithCredentials(loginData);
  }

  /** returns if account is OK, exception if not */
  async doAccountCheckoutChecks(refreshFirstFromApi: boolean) {
    if (refreshFirstFromApi) {
      await this.refreshProfileData();
    }
    const profile = this.profileData$.value;
    const profileNotCreated = !profile || profile.role === 'guest';
    const profileNotConfirmedYet = profile && profile.role === 'unvalidated';
    if (profileNotCreated) {
      throw {
        error: new SubmitError({ informCallback: this.accountCreationCallback.bind(this) }),
        alertMessage: AlertMessages.createAccountCheckout,
      };
    } else if (profileNotConfirmedYet && AppConfig.checkout.emailValidationIsRequired) {
      await this.router.navigateRoot('welcome', {
        animated: true
      });
      await this.logout();
      throw { error: {}, alertMessage: AlertMessages.accountNotConfirmed };
    } else if (profileNotConfirmedYet && !AppConfig.checkout.emailValidationIsRequired &&
      AppConfig.checkout.alwaysResendEmailValidationEmail) {
      await this.resendConfirmationEmail();
    }
  }

  private accountCreationCallback = () => {
    this.authModal.openModal();
  };
}
