import { AppConfig } from './../../models/constants/app-config';
import { AppRateProvider } from '../../providers/app-rate/app-rate.service';
import { ToastProvider } from './../toast/toast';
import { ToastMessages } from './../../models/constants/toast-messages';
import { Book, BookPrice } from './../../interfaces/books';
import { AlertMessages } from './../../models/constants/alert-messages';
import { AlertsProvider } from './../alerts/alerts';
import { BookListApi } from '../../providers/book/book-list-api';
import { BookListModel } from './../../models/book-list';
import { StorageService } from '../../providers/utils/storage.service';
import { Injectable } from '@angular/core';

@Injectable()
export class BookListToastAlerts {

  toastLevels = {
    // to avoid overlap and make sure a toast is only displayed once
    priceMotivationLevel: 0,
    numberOfScannedBooks: 0,
    numberOfNotInterested: 0,
    numberOfUnknownIsbn: 0,
  };

  counter = 0;
  randomHelpMessages = ToastMessages.helpToasts;

  constructor(
    private appRateProvider: AppRateProvider,
    private alertsProvider: AlertsProvider,
    private toastProvider: ToastProvider,
    private bookListApi: BookListApi,
    private storageService: StorageService,
  ) {

  }

  async getAfterScanMotivation(interestedInBook: boolean, unknownIsbn: boolean, countScannedBooks: boolean, isDonable: boolean, book?: Book) {
    const amountOfLists = await this.bookListApi.getAmountOfLists();

    if (countScannedBooks) {
      this.toastLevels.numberOfScannedBooks++;
    }

    if (unknownIsbn) {
      this.getWrongIsbnMotivation();
      return;
    }

    if (book.reason === 'Te hoog voorraadniveau') {
      this.getTooMuchInventoryMotivation(book.title, book.authors);
      return;
    }

    if (interestedInBook === false) {
      await this.storageService.get('appRateShown');

      if (isDonable === false || !AppConfig.scanner.enableDonateOption) {
        this.getFailSearchMotivation(book, amountOfLists);
      } else {
        const donableToggled = await this.storageService.get('donableToggle');
        if (!donableToggled) {
          this.getDonationToggledOff(book.title, book.authors);
        }
      }
      return;
    }

    if (this.toastLevels.numberOfScannedBooks === 50) {
      const appRateShown = await this.storageService.get('appRateShown');
      if (!appRateShown) {
        this.appRateProvider.showAppRatePrompt();
        await this.storageService.set('appRateShown', true);
      }
    }

  }

  async getBeforeAddToListMotivation(isbn: string, booklist: BookListModel, bookPriceResponse: BookPrice) {

    const book = new Book();
    book.isbn = isbn;
    const countSameBooksAdded = booklist.countBookExistInList(book);

    if (countSameBooksAdded >= AppConfig.bookList.maxDuplicates) {
      await this.alertsProvider.showAlertInform(AlertMessages.bookAddedThrice.title, AlertMessages.bookAddedThrice.message, AlertMessages.bookAddedThrice.button1);
      return Promise.reject('continue');
    }

    if (booklist.additionExceedsMaxAmount(bookPriceResponse.price)) {
      await this.alertsProvider.showAlertInform(AlertMessages.maximumAmountCheckout.title, AlertMessages.maximumAmountCheckout.message, AlertMessages.maximumAmountCheckout.button1);
      return Promise.reject('continue');
    }

  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getAfterAddToListMotivation(booklist: BookListModel, bookPriceResponse: BookPrice) {

    const amountOfLists = await this.bookListApi.getAmountOfLists();

    if (amountOfLists < 2) {

      const bookList = booklist;
      const bookListTotalPrice = bookList.totalPrice;
      const toastLevels = this.toastLevels;

      switch (true) {
      case(toastLevels.numberOfScannedBooks % 5 === 0):
        this.showHelpToast();
        break;
      case (bookListTotalPrice > 1000 && bookListTotalPrice < 5000 && toastLevels.priceMotivationLevel < 2):
        toastLevels.priceMotivationLevel = 2;
        this.toastProvider.showToast(ToastMessages.price10to50.text, ToastMessages.price10to50.duration, ToastMessages.price10to50.position);
        break;
      case (bookListTotalPrice > 1000 && bookListTotalPrice < 5000 && toastLevels.priceMotivationLevel < 3):
        toastLevels.priceMotivationLevel = 3;
        break;
      case (bookListTotalPrice > 5000 && bookListTotalPrice < 6000 && toastLevels.priceMotivationLevel < 5):
        toastLevels.priceMotivationLevel = 5;
        break;
      case (bookListTotalPrice > 40000 && toastLevels.priceMotivationLevel < 8):
        toastLevels.priceMotivationLevel = 9;
        this.toastProvider.showToast(ToastMessages.priceFrom400.text, ToastMessages.priceFrom400.duration, ToastMessages.priceFrom400.position);
        break;
      default:
        this.showHelpToast();
      }
    }
  }

  // after adding book to list
  async showHelpToast(): Promise<void> {
    let message = this.randomHelpMessages[this.counter];

    if (!message) {
      this.counter = 0;
      message = this.randomHelpMessages[this.counter];
    }

    this.toastProvider.showToast(message, 6000, 'bottom');
    this.counter++;
  }

  private getWrongIsbnMotivation() {
    this.toastLevels.numberOfUnknownIsbn++;
    if (this.toastLevels.numberOfUnknownIsbn === 1) {
      this.toastProvider.showToast(ToastMessages.unknownISBN.text, ToastMessages.unknownISBN.duration, ToastMessages.unknownISBN.position);
    } else if (this.toastLevels.numberOfUnknownIsbn > 1) {
      this.toastProvider.showToast(ToastMessages.unknownISBNTwice.text, ToastMessages.unknownISBNTwice.duration, ToastMessages.unknownISBNTwice.position);
    }
  }

  private getTooMuchInventoryMotivation(title: string, author: string) {
    this.toastLevels.numberOfNotInterested++;

    const toastMessage = ToastMessages.tooMuchInventory.text
      .replace('%title', title)
      .replace('%author', author);
    this.toastProvider.showToast(toastMessage, ToastMessages.tooMuchInventory.duration, ToastMessages.tooMuchInventory.position);
  }

  private getDonationToggledOff(title: string, author: string) {
    const toastMessage = ToastMessages.donationToggledOff.text
      .replace('%title', title)
      .replace('%author', author);

    this.toastProvider.showToast(toastMessage, ToastMessages.donationToggledOff.duration, ToastMessages.donationToggledOff.position);
  }

  private getFailSearchMotivation(book: Book, amountOfLists: number) {
    if (!book) {
      return;
    }

    this.toastLevels.numberOfNotInterested++;
    if (this.toastLevels.numberOfNotInterested % 5 === 0 && amountOfLists < 2) {
      this.toastProvider.showToast(ToastMessages.noBid5times.text, ToastMessages.noBid5times.duration, ToastMessages.noBid5times.position);
    } else {
      const toastMessage = ToastMessages.offerIsZero.text
        .replace('%title', book.title)
        .replace('%author', book.authors);
      this.toastProvider.showToast(toastMessage, ToastMessages.offerIsZero.duration, ToastMessages.offerIsZero.position);
    }
  }
}
