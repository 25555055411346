import {Injectable} from '@angular/core';

@Injectable()
export class ValidationUtil {
  constructor() {
  }

  isValidEmail(email: any): boolean {
    return email && (email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) !== null);
  }

  isValidPasswordLength(password: any): boolean {
    return password && password.length >= 8;
  }

  isValidLoginPasswordLength(password: any): boolean {
    return password && password.length >= 6;
  }

  isValidPasswordFormat(password: any): boolean {
    return password && /\d/.test(password);
  }

  isValidPassword(password: any): boolean {
    return this.isValidPasswordLength(password) && this.isValidPasswordFormat(password);
  }

  isValidLoginPassword(password: any): boolean {
    return this.isValidLoginPasswordLength(password);
  }

}
