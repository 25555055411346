const defaultTitle = 'We helpen je graag';
const defaultErrorTitle = 'Er ging iets mis';

export const AlertMessages = {

  Default: {
    title: defaultTitle,
    button1: 'Doorgaan',
  },

  /* Scanner alerts */
  IsbnNotFound: {
    title: defaultTitle,
    message: 'Dit ISBN nummer kennen we helaas niet.',
    button1: 'Doorgaan',
  },
  IsbnNotFilled: {
    title: defaultTitle,
    message: 'Er is geen ISBN nummer ingevuld.',
    button1: 'Doorgaan',
  },
  IsbnInvalid: {
    title: defaultTitle,
    message: 'Het ISBN nummer is ongeldig, type het ISBN nummer nogmaals in en controleer op typefouten.',
    button1: 'Doorgaan',
  },
  SomethingWentWrong: {
    title: defaultTitle,
    message: 'Er is iets misgegaan. Probeer het later opnieuw.',
    button1: 'Doorgaan',
  },
  openScannerPWA: {
    title: defaultTitle,
    message: 'Heb je veel boeken die je wil aanbieden? Download dan de BoekenBalie app, waarmee je streepjescodes kunt scannen.',
    button1Android: 'Naar de Google Play Store',
    button1iOS: 'Naar de App Store',
    button2: 'Doorgaan',
  },
  openScannerDesktop: {
    title: defaultTitle,
    message: 'Streepjescodes kun je scannen met de BoekenBalie app. Download de app, of voer een ISBN nummer in.',
    button1: 'Doorgaan',
  },
  noCameraPermission: {
    title: defaultTitle,
    message: 'We hebben geen toestemming tot de camera. Open de instellingen en geef toestemming als je de barcode scanner wilt gebruiken.',
    button1: 'Doorgaan',
    button2: 'Annuleren'
  },
  bookAddedTwice: {
    title: 'Maximaal 1 per titel',
    message: 'Klopt het dat je hetzelfde boek nog een keer wilt toevoegen?',
    button1: 'Ja',
    button2: 'Nee',
  },
  bookAddedThrice: {
    title: 'Maximaal 1 per titel',
    message: 'We nemen niet hetzelfde boek 2 keer aan. Misschien kun je dit boek doneren aan een goed doel.',
    button1: 'Doorgaan',
  },

  /* Booklist/Account alerts */
  booklistTransferred: {
    title: defaultTitle,
    message: 'Je bestaande boekenlijst is omgezet naar je nieuwe account.',
    button1: 'Doorgaan',
  },
  booklistTransferredAccount: {
    title: defaultTitle,
    message: 'Je bestaande boekenlijst is omgezet naar je account.',
    button1: 'Doorgaan',
  },
  booklistNoBooks: {
    title: defaultTitle,
    message: 'Er hoeven geen boeken omgezet te worden naar je account.',
    button1: 'Doorgaan',
  },
  accountNotConfirmed: {
    title: defaultTitle,
    message: 'Uw account is niet geverifieerd. Verifieer uw account en probeer opnieuw in te loggen.',
    button1: 'Oké',
  },
  errorLoadingList: {
    title: defaultTitle,
    message: 'Er ging iets fout bij het ophalen van de lijst.',
    button1: 'Sluiten',
  },
  resetSession: {
    title: defaultTitle,
    message: 'Je bent nu ingelogd als gast. Als je uitlogt worden je gescande boeken verwijderd. Maak een account aan om je boekenlijst te bewaren.',
    button1: 'Herstellen',
    button2: 'Annuleren',
  },
  confirmEmailConfirmation: {
    title: 'Bevestigen',
    message: 'We sturen een e-mail naar ',
    message2: ' om je account te bevestigen. Als dit e-mailadres niet klopt, kun je deze aanpassen in je account.',
    button1: 'Verzenden',
    button2: 'Sluiten',
  },
  sendingConfirmation: {
    title: 'E-mail verstuurd',
    message: 'We hebben een e-mail verstuurd om je account te bevestigen. Indien je geen e-mail hebt ontvangen, controleer dan ook je spam.',
    button1: 'Sluiten',
  },
  navigateToNativeApp: {
    title: defaultTitle,
    message: 'Je wachtwoord is nu gewijzigd. Bevestig hieronder of je terug naar de app wilt gaan of in de browser wilt blijven.',
    button1: 'Naar de app',
    button2: 'Blijf in browser',
  },
  donableInformation: {
    title: defaultTitle,
    message: 'Op basis van onze ervaring is het voor ons niet winstgevend om dit boek te verkopen omdat deze te lang in de voorraad moet staan. Daarom kunnen we je er geen bod voor doen. Omdat we graag positieve impact willen maken, planten wij bomen voor boeken die er voor in aanmerking komen om gedoneerd te worden. We doen dit via <a target="_blank" href="https://treesforall.nl/">Treesforall</a>.',
    button1: 'Sluiten',
  },
  /* Checkout alerts */
  deleteBook: {
    title: defaultTitle,
    message: 'Weet je zeker dat je dit boek wilt verwijderen?',
    button1: 'Ja',
    button2: 'Nee',
  },
  deleteAllBooks: {
    title: defaultTitle,
    message: 'Weet je zeker dat je de gehele lijst wilt verwijderen?',
    button1: 'Ja',
    button2: 'Nee',
  },
  minimumAmountCheckout: {
    title: defaultTitle,
    message: 'Vanaf €10,- of 10 boeken kun je je boeken aan ons verkopen.',
    button1: 'Boek toevoegen',
    button2: 'Sluiten',
  },
  maximumAmountCheckout: {
    title: defaultTitle,
    message: 'Je kunt voor maximaal €400,- per keer boeken aan ons verkopen.',
    button1: 'Sluiten',
  },
  createAccountCheckout: {
    title: defaultTitle,
    message: 'Om verder te kunnen moet je eerst een account aanmaken of inloggen met je bestaande account.',
    button1: 'Inloggen of registreren',
    button2: 'Sluiten',
  },
  createAccountScanner: {
    title: defaultTitle,
    message: 'Om je boeken straks af te kunnen rekenen moet je eerst een account aanmaken of inloggen met je bestaande account.',
    button1: 'Inloggen of registreren',
    button2: 'Doe ik later',
  },
  grantPermissions: {
    title: defaultErrorTitle,
    message: 'Misschien hebben we nog geen toegang tot de camera. Als je ons toestemming geeft, kun je gebruik maken van de barcode scanner.',
    button1: 'Toegang geven',
    button2: 'Annuleren',
  },
  errorDuringSubmit: {
    title: defaultTitle,
    message: 'Er ging iets mis. Probeer het na 5 minuten nog eens of probeer het via onze webapp op <a target="_blank" href="https://verkoop.boekenbalie.nl/">verkoop.boekenbalie.nl</a>.',
    button1: 'E-mail sturen',
    button2: 'Sluiten'
  },
  actionRequiredDuringSubmit: {
    title: defaultTitle,
    message: '',
    button1: 'Ga terug',
    button2: 'Sluiten',
  },
  removeUnselectedBooksFromList: {
    title: defaultTitle,
    message: 'Niet geselecteerde boeken worden verwijderd van de boekenlijst. Wil je doorgaan?',
    button1: 'Doorgaan',
    button2: 'Annuleren',
  },
  deleteUser: {
    title: 'Je account definitief verwijderen',
    message: 'Het verwijderen van je account kan niet ongedaan worden gemaakt. Weet je zeker dat je wil doorgaan?',
    button1: 'Doorgaan',
    button2: 'Annuleren',
  },
};
