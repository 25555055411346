import { Component } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

@Component({
  selector: 'page-update',
  templateUrl: 'update.html',
  styleUrls: ['update.scss'],
})
export class UpdatePage {

  isForced: string;

  constructor(
    public platform: Platform,
    private modalCtrl: ModalController,
  ) {
    document.addEventListener('backbutton', this.backButtonListener, false);
  }

  ionViewWillLeave() {
    document.removeEventListener('backbutton', this.backButtonListener, false);
  }

  private readonly backButtonListener = (event) => {
    event.preventDefault();
    event.stopPropagation();
    return false;
  };

  closeModal() {
    this.modalCtrl.dismiss();
  }

  openStorePage() {
    if (this.platform.is('android')) {
      window.open('https://play.google.com/store/apps/details?id=com.boekenbalie.app', '_system');
    } else if (this.platform.is('ios')) {
      window.open('https://itunes.apple.com/us/app/boekenbalie/id1115737133', '_system');
    }
  }

}
