import { ENV } from './../env/env';
import { NavService } from './../providers/utils/nav.service';
import { CheckoutModule } from './../checkout/checkout.module';
import { HeaderModule } from './../components/header/header.module';
import { ScannerModule } from './../scanner/scanner.module';
import { BookListToastAlerts } from './../providers/book/booklist-toast-alerts.service';

import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicModule } from '@ionic/angular';
import { Items } from '../mocks/providers/items';
import { Market } from '@awesome-cordova-plugins/market/ngx';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { AppRate } from '@awesome-cordova-plugins/app-rate/ngx';

// COMPONENTS
import { SubHeaderModule } from './../components/sub-header/sub-header.module';
import { OfflinePage } from './../pages/offline/offline';
import { UpdatePage } from './../pages/update/update';
import { StateConditionsPage } from '../pages/information/state-conditions/state-conditions.component';

// PROVIDERS
import { AppRateProvider } from '../providers/app-rate/app-rate.service';
import { BookListApi } from '../providers/book/book-list-api';
import { BoekenbalieApi } from '../providers';
import { ProfileProvider } from './../providers/profile/profile.service';
import { ToastProvider } from '../providers/toast/toast';
import { LoadingProvider } from '../providers/loading/loading';
import { PreloadingStrategyService } from '../providers/utils/preload.service';
import { PlatformService } from './../providers/utils/platform.service';

import { AlertsProvider } from '../providers/alerts/alerts';
import { BookProvider } from '../providers/book/book.service';
import { HttpService } from '../providers/utils/http.service';
import { AppSettings } from './app.settings';
import { AuthenticationProvider } from '../providers/authentication/authentication';
import { StorageService } from '../providers/utils/storage.service';
import { MyApp } from './app.component';
import { LogisticsProvider } from './../providers/logistics/logistics';
import { Network } from '@awesome-cordova-plugins/network/ngx';

import { PipesModule } from './../pipes/pipes.module';
import { AppErrorHandlerProvider } from '../providers/app-error-handler/app-error-handler';
import { BooklistEventsProvider } from '../providers/book/book-list-events.service';
import { BackButtonUtil } from '../providers/utils/back-button-util';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { AppAvailability } from '@awesome-cordova-plugins/app-availability/ngx';
import { BookListStore } from '../providers/book/book-list-store';
import { OfflineModalProvider } from '../providers/utils/offline-modal.service';
import { AuthenticationModalProvider } from '../providers/utils/authentication-modal.service';
import { StateConditionsModalProvider } from '../providers/utils/state-conditions.service';
import { UpdateModalProvider } from '../providers/utils/update-modal.service';
import { ConnectivityProvider } from '../providers/connection/connectivity.provider';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { EventsService } from './../providers/utils/events-service';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import {ValidationUtil} from '../providers/utils/validation-util';

if (ENV.production) {
  window.console.log = function () {};
  window.console.warn = function () {};
  window.console.error = function () {};
  (window.console as any).exception = function () {};
}

// The translate loader needs to know where to load i18n files
// in Ionic's static asset pipeline.
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

function initializeApp(platformService: PlatformService) {
  return async () => {
    try {
      if (!(await platformService.IsApp()) && ENV.firebase?.projectId) {
        // Web only, app automatically reads from json/plist
        await FirebaseAnalytics.initializeFirebase(ENV.firebase);
      }
    } catch (error) {
      console.error(error);
    }
  };
}

@NgModule({
  declarations: [
    MyApp,
    StateConditionsPage,
    OfflinePage,
    UpdatePage,
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    IonicModule.forRoot({ innerHTMLTemplatesEnabled: true }),
    IonicStorageModule.forRoot(),
    SubHeaderModule,
    HeaderModule,
    CheckoutModule,
    ScannerModule,
    AngularFireModule.initializeApp(ENV.firebase),
    AngularFireAnalyticsModule
  ],
  bootstrap: [MyApp],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [
        PlatformService
      ]
    },
    BoekenbalieApi,
    AuthenticationProvider,
    AppRate,
    AppRateProvider,
    EventsService,
    Items,
    ProfileProvider,
    Deeplinks,
    HttpService,
    { provide: ErrorHandler, useClass: AppErrorHandlerProvider },
    ToastProvider,
    LoadingProvider,
    BookProvider,
    BooklistEventsProvider,
    AlertsProvider,
    BookProvider,
    AppSettings,
    PreloadingStrategyService,
    StorageService,
    LogisticsProvider,
    PipesModule,
    AppErrorHandlerProvider,
    BookListToastAlerts,
    BackButtonUtil,
    Network,
    AppVersion,
    AppAvailability,
    BookListApi,
    BookListStore,
    OfflineModalProvider,
    AuthenticationModalProvider,
    StateConditionsModalProvider,
    UpdateModalProvider,
    ConnectivityProvider,
    Market,
    NavService,
    PlatformService,
    ValidationUtil,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
